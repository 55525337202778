import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const MbaWritingServicePage = () => (
  <Layout
    pageWrapperClassName="mba-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="MBA Essay Writing Service - Order MBA Essays"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 is the leading Mba Essay Writing Service  that offers professional writing assistance 24/7. Our customers rate us positively, as every Mba Assignment Writing Service is completed with guarantees of timely delivery, high quality, and 100% originality.',
        },

        {
          property: 'og:title',
          content: 'Custom MBA Essay Writing Service: The Best Papers for the Lowest Price | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Looking for a service where you can get your MBA essay for a good price? We are the custom MBA essay writing service, and we are going to help you out with all types of assignments. If you are ready to place an order, go to our website and fill in the details of your essay.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/mba-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Stuck with yet
        <br className="first-screen-section__title__br" />
        {' '}
        another MBA paper?
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Buy an essay from us,
        <br className="first-screen-section__heading__br" />
        {' '}
        and we will help you get rid of your essay writing problems
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Order mba essays: mba essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            MBA is definitely one of the hardest degree programs since students have to work on many
            assignments and write a lot of papers. If you are an MBA student, you know the struggle.
            We are the paper writing service that is going to help you complete any kind of paper.{' '}
            <strong>
              With our help, you can improve your writing and finally find some free time to do some
              things that you were always interested in. Here on this page, you can learn all the
              important information about our services and the benefits of our company.
            </strong>
          </p>
          <h2 className="h3">Buy MBA essay with the best professionals </h2>
          <p>
            “Write my MBA essay” is the only thing that you should send to our service to get done
            with your assignments. Our service works with qualified experts who are ready to assist
            you with any task. When you buy MBA essay online with our company, you pay for the
            assignment of the highest quality. “Can I trust you to help me with my homework?” is
            something many students ask. Our online helper is going to write an incredible paper, so
            you can be sure that you will learn much from it and achieve the greatest grade for your
            own writing.
          </p>
          <p>
            If you need to buy MBA essay, our service is ready to write it for you. We are the
            online service that is not going to let you down. Why choose us over other companies?
            Here are some of the advantages of picking our custom MBA essay writing service:
          </p>
          <ol>
            <li>
              All the information you share with us is confidential. Our custom MBA essay writing
              service is not going to put you in any risky situation. Connection on our website is
              completely secured, and thanks to the cybersecurity software, there is no chance of
              any possible attacks. We apply the GDPR and our privacy policy to protect your
              personal information.
            </li>
            <li>
              Professional writers with large experience. When you purchase a paper with our
              service, you receive an assignment written in excellent English. We hire only those
              experts who obtain the skills and knowledge necessary for working on all kinds of
              assignments. We cooperate with responsible and qualified experts.
            </li>
            <li>
              Affordable papers for the cheapest rates. Our MBA essay writing service offers
              students extremely low prices for the assignments. We don’t want you to spend all of
              your money on papers. Quality doesn’t have to be expensive. Buy the best essays for
              sale for a cheap price without any hidden costs.
            </li>
            <li>
              Plagiarism free assignments. For us, it is important to make sure that not only the
              quality of the paper is going to be on a high level, but also that the assignment is
              free from plagiarism. We provide you with the reports of an anti-plasgiarism software,
              so you can be sure that the paper is completely authentic and non-plagiarized.
            </li>
            <li>
              Custom assignments of any type. Our writer is going to take care of even the hardest
              task. We are ready to help you with your dissertation, research analysis, term paper,
              college assignments, essays, as well as many other papers. All of the paid assignments
              are going to be done according to all the instructions you give us.
            </li>
            <li>
              Urgent papers. Do you have an essay to write and submit it soon? Don’t worry about
              deadlines: we will start writing your paper the minute after you made an order. Our
              MBA essay writing service will do any assignment fast, so you will have time to learn
              from it and compose your own paper.
            </li>
            <li>
              Legit services. With us, you don’t have to risk anything. Our company has been helping
              students for many years, and 98% of them rated our service as a top one. Your data
              will be completely safe when you order with us.
            </li>
          </ol>
          <h2 className="h3">Who can write my MBA essay: use the safest writing service</h2>
          <p>
            These days, it is always important to make sure that you won’t get into something risky
            while using the Internet. There are many different websites we use every day, and this
            is why it is important to double-check each of them.
          </p>
          <p>
            With us, you don’t have to worry about anything when using our services. Your data is in
            good hands. Anything you share with us is confidential. All of the chats and messages
            are secured, so the details of your order, assignment, as well as transactions are going
            to be protected.
          </p>
          <h2 className="h3">Contact our service and pay for MBA essay easily</h2>
          <p>
            With our writing service, you don’t have to pay a lot to get done with your homework.
            “Do my MBA essay” is the only message you should send us in order to receive help and
            complete your custom MBA essays. We offer the cheapest rates and 24/7 service, so
            whenever you decide to buy your assignment with us, you can feel free to contact us at
            any time convenient for you.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Get assistance">
      Breathe in, breath out. Relax.
      <br />
      {' '}
      We’ve got you covered.
    </CTA>

  </Layout>
);

export default MbaWritingServicePage;
